.deals-table {
  font-size: 12px;

  .-header {
    font-weight: bold !important;
  }

  button:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .icon-elec {
    color: $elec;
  }

  .icon-gaz {
    color: $gas;
  }
}

.deals-graph {
  display: flex;
  flex-direction: column;

  header {
    padding: 0 25px !important;
    box-shadow: unset !important;
    margin: 15px 15px 0;
    border-bottom: 1px solid rgb(216, 218, 223);

    .col {
      padding: 0;
    }

    .nav-link {
      border: 1px solid rgb(216, 218, 223) !important;
      margin-right: -1px;
      padding: 0.4rem 1rem !important;
    }
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }

  .parcel-nav {
    margin-bottom: 20px;
  }

  .currentMonth {
    background-color: $gray-darken;
    color: white;
  }

  #coverageFollowup tr td:last-child {
    font-weight: bold;
  }

  .parcel-nav-link {
    margin-left: 0px;
    margin-right: 0px;
    border-bottom: 1px solid rgb(216, 218, 223);

    .col {
      padding: 0;
    }

    .nav {}

    .nav:not(.nav-tabs) {
      .nav-item {
        position: relative;

        &:before {
          content: "S";
          font-family: "eleneo";
          color: $gray-lighter;
          position: absolute;
          top: 50%;
          left: -40px;
          font-size: 20px;
          transform: translate(0, -50%);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }

        a {
          color: $gray-light;
          font-size: 14px;
          padding: 11px 60px 11px 0;
          display: inline-block;

          .badge {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            padding: 0;
            font-size: 14px;
            font-weight: 500;
            background: none;
            border: 1px solid $gray-light;
            color: $gray-light;
            line-height: 1.3;
            vertical-align: top;
            position: relative;
            margin-right: 4px;
          }

          &.active {
            color: $primary;

            .badge {
              color: $white;
              background: $primary;
              border-color: $primary;
            }
          }
        }
      }
    }

    .nav.nav-tabs {
      display: inline-block;
      vertical-align: bottom;
      margin-left: 10px;
      border: none;

      .nav-item {
        display: inline-block;

        .nav-link {
          padding: 0.4rem 1.6rem;
          cursor: pointer;
          color: $gray;
          border: 1px solid $gray-lighter;
          border-right: none;
          border-radius: 0;
          font-weight: bold;

          &.active {
            color: $white;
            cursor: default;
            background: $primary;
            border-color: $primary;
          }
        }

        &:first-child {
          .nav-link {
            border-top-left-radius: 2px;
          }
        }

        &:last-child {
          .nav-link {
            border-top-right-radius: 2px;
            border-right: 1px solid $gray-lighter;
          }
        }
      }
    }
  }

  .performance-table {
    text-align: right;
  }
}
