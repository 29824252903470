// External modules
@import "~react-table/react-table.css";
@import "~@reach/accordion/styles.css";
@import "~@reach/listbox/styles.css";
@import "~@reach/tabs/styles.css";

// Icons
@import "icons";

[class^="icon-"]:before,
[class*="icon-"]:before {
  vertical-align: middle;
}

// Bootstrap & Emotion variables
@import "variables.module";

// Custom Bootstrap
@import "bootstrap";

// SCSS modules
@import "dashboard";
@import "react-datetime";
@import "dateRange";
@import "buttons";
@import "topNav";
@import "header";
@import "sidebar";
@import "panels";
@import "nav-tabs";
@import "forms";
@import "tables";
@import "popover";
@import "login";
@import "version";
@import "searchPanel";
@import "strategy";
@import "graph";
@import "execution";
@import "kpi";
@import "contract-parcel";
@import "loader";
@import "contracts";
@import "accordion";
@import "deals";
@import "alerts";
@import "notification";
@import "tabs";
@import "utils";

// Themes
@import "themes/vinci";
@import "themes/stef";

// Scrollbars
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba($gray-500, 1);
}

::-webkit-scrollbar-track {
  background: rgba($gray-300, 1);
}

// Global rules
body {
  font-size: 12px;
  overflow: overlay;
  &.no-scroll {
    overflow: hidden;
  }
}

button, input, optgroup, select, textarea {
  font-family: $font-family-sans-serif;
}

.flex-grow {
  flex-grow: 1;
}

.main-col {
  background: $alt-white-dark;
  width: calc(100% - 270px);
  overflow-x: hidden;
  min-height: 100vh;

  header,
  .navbar,
  section {
    background: $white;
  }

  section {
    border-radius: 3px;
    margin: 0 15px 25px;
    padding: 20px;
    box-shadow: 2px 2px 5px $gray-lighter;

    .section-header {
      margin: 0 -30px 30px;
      padding: 0 15px 25px;
      border-bottom: 1px solid $gray-lighter;
    }

    h5 {
      font-size: $font-size-lg;
      margin-bottom: 20px;
    }
  }
}

.popover {
  z-index: 9999;
}

.price-monitoring {
  .range-input-datepicker {
    z-index: 9;
    position: absolute;
    top: 5px;
    right: 52px;
  }

  .highcharts-input-group {
    display: none;
  }
}

.execution {
  .range-input-datepicker {
    z-index: 9;
    position: absolute;
    top: 34px;
    right: 52px;
  }

  .highcharts-input-group {
    display: none;
  }
}

#alerts {
  .range-input-datepicker {
    z-index: 9;
    position: absolute;
    top: 7px;
    right: 52px;
  }

  .highcharts-input-group {
    display: none;
  }
}

.alerts {
  .range-input-datepicker {
    z-index: 9;
    position: absolute;
    top: 65px;
    right: 72px;
  }

  .highcharts-input-group {
    display: none;
  }
}

.global-placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.spinner {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

path.highcharts-legend-nav-active {
  fill: #00c793;
}

path.highcharts-legend-nav-inactive {
  fill: $gray;
}

.highcharts-credits {
  display: none;
}

.highcharts-legend-item {
  .highcharts-grid-line {
    display: none !important;
  }
}

// Listbox

[data-reach-listbox-popover]:focus-within {
  box-shadow: none;
  outline: none;
}

[data-reach-listbox-option][aria-selected="true"] {
  background: transparentize($secondary, 0.3);
  color: #fff;
}

// Highcharts

.highcharts-root {
  font-family: $font-family-sans-serif !important;
}

// Titles

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: #3d4246;
}

// Loading states

.isFetching {
  pointer-events: 'none';
  opacity: 0.5;
}

// forms
input[type="date"]::-webkit-calendar-picker-indicator {
  padding: 0 0 0 4px;
  margin: 0;
}
input[type=date]:invalid::-webkit-datetime-edit {
  opacity: .6;
}
input[type=date]:focus::-webkit-datetime-edit {
  opacity: 1;
}