.contract-section {
  max-height: calc(100vh - 369px);
  overflow-y: scroll;
  border-top: 1px solid $gray-lighter;

  label {
    color: $gray;
  }
}

.select-buttons {
  padding-top: 15px !important;
  padding-bottom: 10px !important;
  border-bottom: 0px !important;
}
