.btn.standard-width {
  width: 200px;
}

.btn.btn-link.prec-button {
  border: none;
  color: '#98D2DD';
  font-size: 16px;

  &:hover {
    color: $gray-light;
  }
}

.btn-table {
  padding: 0;
  font-size: inherit;
  border-radius: 0;
  border: none;
  background: white;
  color: '#98D2DD';

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: $gray;
    background: none;
    box-shadow: none;
  }

  &:hover {
    color: $primary !important;
  }

  &:focus {
    outline: none;
  }
}

button.close:focus {
  outline: none
}