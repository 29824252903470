.dashboard-head {
  background: $alt-white-dark;
  color: $gray;
  font-size: 18px;

  .col,
  [class^="col"] {
    > span {
      padding: 5px 0;
      display: block;
    }

    &.active {
      background: $primary;
      color: $white;
    }
  }
}

.dashboard-sub-head {
  font-size: 12px;
}

.dashboard-country {
  font-size: 16px;
}

.dashboard-card {
  margin: 0 3px 6px;
  border-radius: 0;

  .card-body {
    padding: 10px 15px;

    .card-title {
      font-size: 14px;
      margin: 0 0 6px;
      font-weight: bold;
    }

    .card-text {
      margin: 0;
    }

    .card-link {
      color: inherit;
      font-size: 18px;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.dashboard-card-elec {
    background: lighten($blue, 30);
    border-color: $blue;
    color: $blue;
  }

  &.dashboard-card-gaz {
    background: lighten($purple, 30);
    border-color: $purple;
    color: $purple;
  }
}
