.tabs-pills {
  [data-reach-tab-list] {
    background: transparent;
  }

  [data-reach-tab] {
    border: none;
    margin-right: .5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.125rem;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px #21215A30;
    }
  }

  [data-selected] {
    background: var(--secondary);
    color: white;
    font-weight: 700;
  }

  [data-reach-tab-panel] {
    &:focus {
      outline: none;
    }
  }
}