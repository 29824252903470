$icons-path: "../fonts/eleneo/eleneo";

@charset "UTF-8";

/* eleneo */
@font-face {
  font-family: "eleneo";
  src:url($icons-path+".eot");
  src:url($icons-path+".eot?#iefix") format("embedded-opentype"),
    url($icons-path+".woff") format("woff"),
    url($icons-path+".ttf") format("truetype"),
    url($icons-path+".svg#eleneo") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "eleneo" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "eleneo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-date:before {
  content: "\61";
}
.icon-bigarrow-down:before {
  content: "\62";
}
.icon-bigarrow-left:before {
  content: "\63";
}
.icon-bigarrow-right:before {
  content: "\64";
}
.icon-bigarrow-up:before {
  content: "\65";
}
.icon-ccourbes:before {
  content: "\66";
}
.icon-coche-check:before {
  content: "\67";
}
.icon-coche-moins:before {
  content: "\68";
}
.icon-coche-off:before {
  content: "\69";
}
.icon-collapse-moins:before {
  content: "\6a";
}
.icon-collapse-plus:before {
  content: "\6b";
}
.icon-comment:before {
  content: "\6c";
}
.icon-cross:before {
  content: "\6e";
}
.icon-diag:before {
  content: "\6f";
}
.icon-elec:before {
  content: "\70";
}
.icon-eye:before {
  content: "\71";
}
.icon-gaz:before {
  content: "\72";
}
.icon-pdf:before {
  content: "\73";
}
.icon-smallarrow-down:before {
  content: "\74";
}
.icon-smallarrow-left:before {
  content: "\75";
}
.icon-smallarrow-right:before {
  content: "\76";
}
.icon-smallarrow-up:before {
  content: "\77";
}
.icon-user:before {
  content: "\78";
}
.icon-coche-off-inactive:before {
  content: "\79";
}
.icon-done:before {
  content: "\7a";
}
.icon-event:before {
  content: "\42";
}
.icon-file-download:before {
  content: "\43";
}
.icon-ion-document:before {
  content: "\44";
}
.icon-taxes:before {
  content: "\45";
}
.icon-truck:before {
  content: "\41";
}
.icon-vallet:before {
  content: "\46";
}
.icon-eclair:before {
  content: "\47";
}
.icon-power:before {
  content: "\48";
}
.icon-gaz-outline:before {
  content: "\49";
}
.icon-courbe-full:before {
  content: "\6d";
}
.icon-ico-alarm:before {
  content: "\4a";
}
.icon-ico-alert-daily:before {
  content: "\4b";
}
.icon-ico-trash:before {
  content: "\4c";
}
.icon-ico-menu-parameters:before {
  content: "\4d";
}
.icon-ico-menu-dashboard:before {
  content: "\4e";
}
.icon-ico-edit:before {
  content: "\4f";
}
.icon-ico-brdcrmb-plain:before {
  content: "\50";
}
.icon-ico-brdcrmb-empty:before {
  content: "\51";
}
.icon-ico-alert-mail:before {
  content: "\52";
}
.icon-ico-arrow-breadcrumb:before {
  content: "\53";
}
.icon-ico-checked:before {
  content: "\54";
}
.icon-ico-menu-burger:before {
  content: "\55";
}
.icon-ic-add-alert:before {
  content: "\56";
}
.icon-ic-alert-off:before {
  content: "\57";
}
.icon-ic-timeline:before {
  content: "\58";
}
.icon-ic-settings:before {
  content: "\59";
}
.icon-ic-ratio:before {
  content: "\5a";
}
.icon-ic-open-new:before {
  content: "\30";
}
.icon-ic-notif:before {
  content: "\31";
}
.icon-ic-move:before {
  content: "\32";
}
.icon-ic-mobile-vibe:before {
  content: "\33";
}
.icon-ic-menu:before {
  content: "\34";
}
.icon-ic-flip-to:before {
  content: "\35";
}
.icon-ic-fiche:before {
  content: "\36";
}
.icon-ic-diagram:before {
  content: "\37";
}
.icon-filter:before {
  content: "\38";
}
.icon-search:before {
  content: "\39";
}
