.popover {
  max-width: 100%;
  margin-right: 40px;
  border-radius: 0;

  .popover-inner {
    width: 425px;
    padding: 5px;

    .popover-body {
      .row {
        margin-bottom: 15px;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        color: $gray-900;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  &.popover-menu-sticky {
    .popover-inner {
      padding: 0;
      width: 220px;

      .popover-body {
        padding: 0;

        .nav {
          flex-direction: column;

          .nav-item {
            .nav-link {
              color: $primary;
              border-bottom: 1px solid $gray-lighter;
              font-size: 16px;
              padding: 15px 20px;

              .badge {
                border-radius: 100%;
                background: $red;
                margin-left: 5px;
              }

              &.active {
                font-weight: bold;
              }

              &:hover {
                color: $white;
                background: $primary;
              }
            }

            &:last-child {
              .nav-link {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
