@import "~react-notifications-component/dist/theme.css";

.notification-success {
  background-color: $cyan;
  border-left-color: darken($cyan, 8%);
  .notification-close {
    background-color: darken($cyan, 4%);
  }
}

.notification-danger {
  background-color: $danger;
  border-left-color: darken($danger, 8%);
  .notification-close {
    background-color: darken($danger, 4%);
  }
}

.notification-info {
  background-color: $purple;
  border-left-color: darken($purple, 8%);
  .notification-close {
    background-color: darken($purple, 4%);
  }
}

.notification-warning {
  background-color: $warning;
  border-left-color: darken($warning, 8%);
  .notification-close {
    background-color: darken($warning, 4%);
  }
}

.notification-item::before {
  content: ' ';
  background-size: 20px;
  width: 45px;
  background-position: 10px center;
  background-repeat: no-repeat;
}

.notification-success::before {
  background-image: url(../../images/success.svg);
}
.notification-danger::before {
  background-image: url(../../images/danger.svg);
}
.notification-info::before {
  background-image: url(../../images/info.svg);
}
.notification-warning::before {
  background-image: url(../../images/warning.svg);
}

.notification-container-bottom-left {
  width: 330px;
}

.notification-content {
  padding-right: 30px !important;
}