.alerts {
  h6 {
    margin-bottom: 20px;
  }
  section {
    min-height: calc(100% - 25px);
  }
  .icon-elec {
    color: $elec;
  }
  .icon-gaz {
    color: $gas;
  }
}

.alerts-table {
  font-size: 12px;
  .-header {
    font-weight: bold !important;
  }
}

.alerts-graph {
  .highcharts-range-selector-buttons {
    @media (max-width: 1700px) {
      display: none;
    }
  }
}