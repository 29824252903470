.login {
  background: $alt-white;
  width: 100%;
  height: 100vh;

  .card {
    width: 360px;
    padding: 10px 40px 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 30px 0 $gray-light;
    background: $gray-dark;
    color: $white;
    height: 460px;
    overflow: hidden;
    border: none;

    img {
      padding: 20px 60px 0;
    }

    .card-body {
      position: absolute;
      top: 118px;
      left: 0;
      right: 0;
      padding: 1.25rem 55px;
      backface-visibility: hidden;
      transform: rotateY(0deg);
      transition: all 0.3s ease;

      &.off {
        transform: rotateY(180deg);
      }

      label {
        text-transform: uppercase;
        color: $gray-light;
        font-size: $font-size-xs;
        margin: 0;
        cursor: pointer;
      }

      .form-group {
        margin-bottom: 40px;

        .form-control {
          background: none;
          box-shadow: none;
          outline: none;
          border: none;
          border-bottom: 1px solid $gray-light;
          border-radius: 0;
          color: $white;
          padding: 0.25em 0 0.5em;
          margin-bottom: 0;

          &:-webkit-autofill {
            color: $white !important;
            -webkit-text-fill-color: $white !important;
            -webkit-box-shadow: 0 0 0px 1000px $gray-dark inset !important;
            transition: background-color 5000s ease-in-out 0s;
          }
        }
      }

      &.error {
        .form-group {
          .form-control {
            border-bottom-color: $red;
            color: $red;

            &:-webkit-autofill {
              color: $red !important;
              -webkit-text-fill-color: $red !important;
            }
          }
        }
      }
    }

    .buttons {
      margin-top: -24px;

      .btn {
        width: 80%;
        margin: 0 auto;
        border-radius: 2em;
        font-size: $font-size-base;
        cursor: pointer;
      }

      .btn-link {
        font-size: $font-size-xs;
        color: $gray-light;
        margin-bottom: 10px;
        outline: none;
        box-shadow: none;
      }
    }

    .error-label,
    .success-label {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: $red;
      text-align: center;
      padding: 0.65em;
      font-size: $font-size-xs;
    }

    .success-label {
      background: $green;
    }
  }
}
