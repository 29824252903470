.contract-parcel {
  .table {
    th, td {
      font-weight: normal;
      padding: 0.4em 1.3em;
    }

    &.table-bordered {
      border: none;

      td {
        border-bottom: 1px solid $gray-lighter;
        vertical-align: middle;

        &:first-child {
          padding-left: 0;
          font-weight: bold;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    &.table-striped {
      th {
        background: $gray-light;
        color: $white;

        &:after {
          position: absolute;
          top: 50%;
          margin-top: -0.7em;
          right: 6px;
        }
      }

      th, td {
        padding-top: 0.4em;
        padding-bottom: 0.4em;
      }
    }

    .form-control {
      display: inline-block;
      width: auto;
      font-size: $font-size-sm;
    }
  }

  .collapse, .collapsing {
    padding: 0;
    border-bottom: 1px solid $alt-white;

    > div {
      padding: 50px 40px 30px;
    }

    .lot-infos {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
}
