.col-header {
  min-height: 30px !important;
}

.big-link {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1rem;
}

.main-col {
  .main-header {
    position: sticky;
    top: 0;
    z-index: 400;

    &.not-sticky {
      position: static;
    }

    &.sticky-top {
      top: 0;
    }

    + * {
      margin-top: 30px;
    }

    header {
      padding: 15px 40px 0;
      box-shadow: 0 -5px 5px 5px $gray-lighter;

      h1 {
        font-size: 14px;
        font-weight: 500;
        color: $gray-light;
        margin-bottom: 0;
      }

      .strat-nav {
        float: right;
        margin-top: -14px;
      }

      .strat-select {
        float: right;
        margin: (-10px) 10px 5px;

        // .Select
        //   margin: 0 5px

      }

      .quit-nav {
        float: right;
        cursor: pointer;
        margin-left: 10px;
        margin-left: 5px;
        margin-top: -8px;
        margin-right: -26px;

        i {
          font-size: 1rem;

          &:hover {
            color: $primary;
          }
        }
      }

      .nav {}

      .nav:not(.nav-tabs) {
        .nav-item {
          position: relative;

          &:before {
            content: "S";
            font-family: "eleneo";
            color: $gray-lighter;
            position: absolute;
            top: 50%;
            left: -40px;
            font-size: 20px;
            transform: translate(0, -50%);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          &:first-child {
            &:before {
              display: none;
            }
          }

          a {
            color: $gray-light;
            font-size: 14px;
            padding: 11px 60px 11px 0;
            display: inline-block;

            .badge {
              width: 20px;
              height: 20px;
              border-radius: 100%;
              padding: 0;
              font-size: 14px;
              font-weight: 500;
              background: none;
              border: 1px solid $gray-light;
              color: $gray-light;
              line-height: 1.3;
              vertical-align: top;
              position: relative;
              margin-right: 4px;
            }

            &.active {
              color: $primary;

              .badge {
                color: $white;
                background: $primary;
                border-color: $primary;
              }
            }
          }
        }
      }

      .nav.nav-tabs {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
        border: none;

        .nav-item {
          display: inline-block;

          .nav-link {
            padding: 0.4rem 1.6rem;
            color: $gray;
            border: 1px solid $gray-lighter;
            border-bottom: none;
            border-right: none;
            border-radius: 0;
            font-weight: bold;

            &.active {
              color: $white;
              background: $primary;
              border-color: $primary;
            }
          }

          &:first-child {
            .nav-link {
              border-top-left-radius: 2px;
            }
          }

          &:last-child {
            .nav-link {
              border-top-right-radius: 2px;
              border-right: 1px solid $gray-lighter;
            }
          }
        }
      }

      .menu-sticky {
        display: none;
        position: relative;
        top: 2px;

        &.affix {
          display: block;
        }

        .btn {
          border-radius: 0;
          border: none;
          color: $primary;
          text-decoration: none;
          cursor: pointer;
          text-transform: uppercase;

          i {
            position: relative;
            top: -2px;
            margin-right: 5px;
            font-size: 18px;

            &.icon-smallarrow-down {
              font-size: 12px;
            }
          }
        }
      }

      .inline-list {
        padding: 0;
        margin: 0;
        list-style: none;
        display: inline-block;

        li {
          display: inline-block;
          padding: 0 16px;
          position: relative;

          &:after {
            content: "";
            display: block;
            position: absolute;
            background: $gray-lighter;
            right: 0;
            top: 3px;
            bottom: 2px;
            width: 1px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }

      .btn-close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        background: $alt-white;
        color: $gray;
        border-radius: 0;
        font-size: 25px;
        padding: 20px 25px;
      }
    }

    h1 {
      display: inline-block;
      padding-right: 10px;
    }
  }
}
