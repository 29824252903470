.graphContainer {
  align-self: flex-end;
}

.hedging-profile-graph:not(.selection-mode) {
  user-select: none;
  margin: 30px 0 20px;

  .hedging-arenh, .hedging-cal, .hedging-quarter, .hedging-month, .hedging-summer, .hedging-winter {
    stroke-width: 1px;
    fill-opacity: 0.3;
    cursor: pointer;
  }

  .hedging-month {
    fill: $gold;
    stroke: $gold;
  }

  .hedging-quarter {
    fill: $cyan;
    stroke: $cyan;
  }

  .hedging-summer {
    fill: hsl(33, 100, 39);
    stroke: hsl(33, 100, 39);
  }

  .hedging-winter {
    fill: hsl(178, 100, 39);
    stroke: hsl(178, 100, 39);
  }

  .hedging-cal {
    fill: $blue;
    stroke: $blue;
  }

  .hedging-arenh {
    fill: $red;
    stroke: $red;
  }

  .hedging-text {
    fill-opacity: 1;
  }
}

.hedging-profile-graph.selection-mode {
  user-select: none;
  margin: 1rem 0;
  position: relative;

  .hedging-arenh, .hedging-cal, .hedging-quarter, .hedging-month, .hedging-summer, .hedging-winter {
    stroke-width: 1px;
    fill-opacity: 0.3;
    cursor: pointer;
  }

  rect, text {
    &.hedging-arenh, &.hedging-cal, &.hedging-quarter, &.hedging-month, &.hedging-summer, &.hedging-winter {
      fill: #A3A8B5;
      stroke: #A3A8B5;

      &.selected {
        // fill-opacity: 0.8
        fill: $primary;
        stroke: $primary;
      }

      &.disabled {
        fill: $gray;
        stroke: $gray;
        cursor: not-allowed;
      }
    }
  }
}

.hedging-graph-row {
  width: 500px;
  height: 450px;
}

.hedging-plan-graph,
.coverage-scalability-graph {
  user-select: none;

  .hedging-plan-actual-summary {
    fill: $gray-lighter;
    stroke: $gray-lighter;
    fill-opacity: 1;
    stroke-width: 1px;
  }

  .hedging-plan-actual,
  .expected-coverage {
    fill: #009FE3;
    stroke: $gold;
    fill-opacity: 0.3;
    stroke-width: 1px;
  }

  .hedging-plan-actual-inflexion {
    fill: #FFF;
    stroke: $gold;
    stroke-width: 1px;
    cursor: pointer;
  }

  .hedging-plan-projection {
    fill: $gray-lighter;
    fill-opacity: 0.3;
  }

  .hedging-plan-line,
  .coverage-scalability-line {
    stroke: $gray-lighter;
  }

  .column-indicators {
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    .hedging-plan-actual-inflexion {
      stroke: $gray-light;
      stroke-dasharray: 3, 3;
      fill-opacity: 0.3;

      &:hover {
        stroke: $gold;
      }

      &.selected {
        stroke: $gold;
        stroke-dasharray: initial;
        animation: none !important;
      }
    }

    .invisible-selection-line {
      opacity: 0;
    }

    &.add-segment {
      opacity: 1;

      .hedging-plan-actual-inflexion {
        animation: pulse-me 1s ease-in-out infinite;
      }
    }
  }

  .consumption {
    // stroke: grey
    stroke: $gold;
    stroke-width: 3px;
    fill: none;

    &.alert {
      stroke-width: 3px;
      stroke: crimson;
    }
  }
}

.coverage-scalability-graph {
  text {
    font-size: 1em;
  }
}

// .hedging-plan-graph,
// .hedging-profile-graph,
// .hedging-cal {
//   text {
//     font-size: 1.5em;
//   }
// }

@keyframes pulse-me {
  0% {
    stroke: #717478;
  }

  50% {
    stroke: #b4bac0;
  }

  100% {
    stroke: #717478;
  }
}

.coverage-recap-graph {
  width: 95%;
  user-select: none;
  margin: 30px 0 0;
  display: inline-block;

  .country {
    fill: whitesmoke;
    stroke: $gray-lighter;
    opacity: 0.3;
  }

  .electricity {
    fill: $elec;
    stroke: $elec;
    fill-opacity: 0.3;
  }

  .gaz {
    fill: $gas;
    stroke: $gas;
    fill-opacity: 0.3;
  }

  .electricity-floating {
    fill: #32b0ef;
    fill-opacity: 0.3;
    stroke: #32b0ef;
  }

  .gaz-floating {
    fill: #d544e2;
    fill-opacity: 0.3;
    stroke: #d544e2;
  }

  .contract-labels {
    transform: rotate(90deg) translate(235px, -1202px);
  }

  .country-name {
    text-anchor: middle;
    font-weight: 700;
  }

  .country-percent, .country-total {
    font-size: 0.7em;
    text-anchor: middle;
  }

  .overlay {
    opacity: 0;
    fill: $gray-lighter;
    cursor: pointer;

    &:hover {
      opacity: 0.3;
    }
  }
}

.coverage-recap-popover {
  .popover-inner {
    padding: 5px 30px;
  }

  .info {
    position: absolute;
    right: 30px;
  }

  .icon-elec {
    color: $elec;
  }

  .icon-gaz {
    color: $gas;
  }

  .info-line {
    border-top: solid 1px $gray-lighter;
    padding-top: 12px;
  }
}

.global-coverage-graph {
  user-select: none;
  width: 5%;
  margin: 30px 0 0;
  display: inline-block;
  font-weight: 100;

  .total,
  .total-text {
    fill: whitesmoke;
    stroke: $gray-lighter;
  }

  .covered,
  .covered-text {
    fill: $gold;
    stroke: $gold;
  }

  .covered,
  .covered-floating,
  .total {
    opacity: 0.3;
  }

  .covered-floating,
  .covered-text-floating {
    fill: $blue;
    stroke: $blue;
  }
}

.portfolio-graph {
  user-select: none;
  margin: 30px 0 20px;

  .year-bar {
    fill: whitesmoke;
  }

  .small-text {
    stroke: none;
    fill-opacity: 1;
  }

  .svg-icon {
    font-family: "eleneo";
    cursor: pointer;
    text-decoration: none;
  }

  .total-coverage {
    fill: whitesmoke;
  }

  .covered-coverage-fixe {
    fill-opacity: 0.3;
    fill: $gold;
    stroke: $gold;
  }

  .covered-coverage-flottant {
    fill-opacity: 0.3;
    fill: $blue;
    stroke: $blue;
  }

  .coverage-legend-text {
    fill: $gray-light;
  }

  .coverage-legend-circle {
    fill-opacity: 0.3;
    stroke-width: 1;
  }

  .coverage-legend-circle-fixed {
    stroke: $gold;
    fill: $gold;
  }

  .coverage-legend-circle-float {
    stroke: $blue;
    fill: $blue;
  }
}

#hedgingProfileReadOnly {
  width: 80%;
}
