.space-x-1>*:not(:last-child) {
  margin-right: 0.25rem;
}

.space-x-2>*:not(:last-child) {
  margin-right: 0.5rem;
}

.space-x-3>*:not(:last-child) {
  margin-right: 1rem;
}

.space-x-4>*:not(:last-child) {
  margin-right: 1.5rem;
}

.space-x-5>*:not(:last-child) {
  margin-right: 2rem;
}

.space-y-1>*:not(:last-child) {
  margin-bottom: 0.25rem;
}

.space-y-2>*:not(:last-child) {
  margin-bottom: 0.5rem;
}

.space-y-3>*:not(:last-child) {
  margin-bottom: 1rem;
}

.space-y-4>*:not(:last-child) {
  margin-bottom: 1.5rem;
}

.space-y-4>*:not(:last-child) {
  margin-bottom: 2rem;
}