.ach-wrap,
.meter-lot-wrap,
.ctr-lot-table,
.ctr-perim-table {
  max-height: 186px;
  overflow-y: auto;
  margin-bottom: 30px;
  border: 1px solid $gray-lighter;

  table {
    margin: 0;

    thead tr th {
      position: sticky;
      top: 0;
      background: $white;
      z-index: 1;
    }
  }
}

.meter-lot-wrap {
  max-height: 296px;
}

.ctr-lot-table,
.ctr-perim-table {
  max-height: 326px;
}

.ctr-perim {
  margin-top: 20px;
}

.ctr-lot-infos {
  td:first-child {
    min-width: 120px;
  }
}
