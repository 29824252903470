.coverage-scalability {
  margin-top: 20px;
  justify-content: center;
}

.alert-graph-popover {
  .popover-inner {
    width: 700px;
  }
}

.coverage-recap-popover {
  .popover-inner {
    width: 300px;
  }
}

.align-right {
  td {
    text-align: right;
  }
}

.transaction-view {
  margin-top: 20px;

  .custom-control-label:after, .custom-control-label:before {
    top: 0.08rem;
  }

  .transaction-table,
  .transaction-graph {
    width: 100%;
    overflow-x: auto;
  }

  .transaction-table::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  .transaction-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .reactable-pagination {
    a {
      display: inline-block;
      padding: 0 16px;

      &.reactable-current-page {
        font-weight: 700;
      }
    }
  }

  .transaction-note {
    text-align: right;
    font-style: italic;
  }

  i {
    &.icon-elec {
      color: $elec;
    }

    &.icon-gaz {
      color: $gas;
    }
  }
}

.execution {
  .red {
    color: red;
  }

  .green {
    color: green;
  }

  .parcel-nav {
    margin-bottom: 20px;
  }

  .currentMonth {
    background-color: $gray-darken;
    color: white;
  }

  #coverageFollowup tr td:last-child {
    font-weight: bold;
  }

  .parcel-nav-link {
    margin-left: 0px;
    margin-right: 0px;

    .nav {}

    .nav:not(.nav-tabs) {
      .nav-item {
        position: relative;

        &:before {
          content: "S";
          font-family: "eleneo";
          color: $gray-lighter;
          position: absolute;
          top: 50%;
          left: -40px;
          font-size: 20px;
          transform: translate(0, -50%);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }

        a {
          color: $gray-light;
          font-size: 14px;
          padding: 11px 60px 11px 0;
          display: inline-block;

          .badge {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            padding: 0;
            font-size: 14px;
            font-weight: 500;
            background: none;
            border: 1px solid $gray-light;
            color: $gray-light;
            line-height: 1.3;
            vertical-align: top;
            position: relative;
            margin-right: 4px;
          }

          &.active {
            color: $secondary;

            .badge {
              color: $white;
              background: $secondary;
              border-color: $secondary;
            }
          }
        }
      }
    }

    .nav.nav-tabs {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      border: none;

      .nav-item {
        display: inline-block;

        .nav-link {
          padding: 0.4rem 1.6rem;
          cursor: pointer;
          color: $gray;
          border: 1px solid $gray-lighter;
          border-bottom: none;
          border-right: none;
          border-radius: 0;
          font-weight: bold;

          &.active {
            color: $white;
            cursor: default;
            background: $primary;
            border-color: $primary;
          }
        }

        &:first-child {
          .nav-link {
            border-top-left-radius: 2px;
          }
        }

        &:last-child {
          .nav-link {
            border-top-right-radius: 2px;
            border-right: 1px solid $gray-lighter;
          }
        }
      }
    }
  }

  .performance-table {
    text-align: right;
  }
}

.execution-timeframe {
  .select-year {
    width: 100px;
    min-width: unset;
  }
}
