.main-col section {
  > .nav-tabs {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    border-bottom: 1px solid $alt-white-dark;

    .nav-item {
      flex-grow: 1;

      .nav-link {
        text-align: center;
        border-radius: 0;
        border: none;
        border-right: 1px solid $alt-white-dark;
        font-size: 18px;
        cursor: pointer;
        transition: all 0.3s ease;
        padding: 11px 0 10px;

        &:hover {
          background: $alt-white;
        }

        i {
          display: inline-block;
          border: 1px solid $alt-white-dark;
          border-radius: 100%;
          width: 22px;
          height: 22px;
          line-height: 1.4;
          font-size: 12px;
          vertical-align: top;
          position: relative;
          top: 3px;
          color: $primary;
          margin-right: 4px;
        }

        &.active {
          background: $primary;
          color: $white;

          i {
            color: $primary;
            background: $white;
            border-color: $white;
          }
        }
      }

      &:last-child {
        .nav-link {
          border-right: none;
        }
      }
    }

    + .tab-content {
      padding-top: 30px;
    }
  }
}
