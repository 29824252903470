.form-control {
  box-shadow: none !important;
}

.filter-section {
  margin-bottom: 8px;

  .filter-title {
    font-size: 18px;
    font-weight: bold;
    color: $gray-900;
    margin-bottom: 8px;
  }

  label {
    color: $gray;
  }
}

.date-range {
  margin-bottom: 0;

  .date-range-wrapper {
    .date-range-global {
      .form-group {
        background-color: white;

        .form-control-label {
          margin-bottom: 0;

          .icon-event:before {
            display: none;
          }
        }

        .rdt {
          width: 150px;

          .form-control {
            padding-left: 0;
            width: 90px;
          }
        }
      }
    }
  }
}

.form-check-list {
  padding-left: 0;
  list-style: none;

  ul {
    margin-top: 3px;
    list-style: none;
    padding-left: 20px;

    li {
      margin-top: 3px;
    }
  }
}

.form-check-label {
  padding: 0;
  cursor: pointer;

  input {
    display: none;
  }

  &:before {
    content: "i";
    font-family: eleneo;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    line-height: 1;
    color: $gray-light;
    margin-right: 0.6em;
  }

  &.disabled {
    &:before {
      content: "y";
      color: $gray-light;
    }
  }

  &.checked {
    &:before {
      content: "g";
      color: $cyan;
    }

    &.disabled {
      &:before {
        color: $gray-light;
      }
    }
  }
}

.form-check {
  .form-control-label {
    &:before {
      content: "";
      width: 14px;
      height: 14px;
      display: inline-block;
      margin-right: 5px;
      border: 1px solid $gray-light;
      border-radius: 100%;
    }

    &.disabled {
      &:before {
        background: $gray-lighter;
      }
    }

    &.checked {
      &:before {
        background: $green;
        border-color: $green;
      }

      &.disabled {
        &:before {
          background: $gray-light;
        }
      }
    }

    input {
      display: none;
    }
  }
}

.form-inline {
  .form-check + .form-check {
    margin-left: 10px;
  }
}

.title.inline {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #61747f;
  margin-right: 20px;
}

.form-control-label {
  cursor: pointer;
}

.choice-group {
  margin-left: 0;
  margin-right: 0;
  display: inline-block;

  &.full-width {
    width: 100%;
    display: flex;

    .form-check {
      flex-grow: 1;
    }
  }

  .form-check {
    margin: 0;
    text-align: center;
    display: inline-block;
    border-top: solid 1px #d7dee4;
    border-bottom: solid 1px #d7dee4;
    border-right: solid 1px #d7dee4;

    &:first-child {
      border-left: solid 1px #d7dee4;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover {
      background-color: $gray-lighter;
    }

    &.disabled {
      &:hover {
        cursor: default;
        background-color: $white;
      }
    }

    .form-check-label {
      color: $gray-dark;
      font-size: 16px;
      padding: 8px;
      width: 100%;

      &.checked {
        color: $white;
      }

      &:before {
        display: none;
      }
    }
  }

  &.last-apart {
    .form-check {
      &:last-child {
        margin-left: 5px;
        border-left: solid 1px #d7dee4;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.last-group-item {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}

.input-box {
  position: relative;
  border: 1px solid $gray-lighter;
  border-radius: 3px;
  padding-top: 6px;
  margin: 0;

  .form-control-label {
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 10px;
    z-index: 1;
  }

  .form-control {
    border: none;
    padding-bottom: 4px;
  }
}
